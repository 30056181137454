import api from '../api'

const state = {
    loaded:  false,
    files: [],
    total: 0,
    formats: {
        image: ['jpeg', 'gif', 'png', 'jpg'],
        pdf: ['pdf'],
        zip: ['zip'],
        text: ['text', 'txt'],
        word: ['doc', 'docx'],
        xls: ['xls', 'xlsx']
    },
};

const getters = {
    files(state) {return state.files},
    fileLoaded(state) { return state.loaded},
    total(state) { return state.total},
    isImage: state => ext => state.formats.image.includes(ext.toLowerCase()),
    isPdf: state => ext => state.formats.pdf.includes(ext.toLowerCase()),
    isZip: state => ext => state.formats.zip.includes(ext.toLowerCase()),
    isText: state => ext => state.formats.text.includes(ext.toLowerCase()),
    isWord: state => ext => state.formats.word.includes(ext.toLowerCase()),
    isXls: state => ext => state.formats.xls.includes(ext.toLowerCase()),
};

const actions = {
    async load({commit}, filter) {
        commit('SET_LOADED', false);

        const response = await api.getFiles(filter)

        commit('SET_FILES', response.data.files);
        commit('SET_TOTAL', response.data.total);
        commit('SET_LOADED', true);
    },
    async dropFiles({commit}, ids) {
        return await Promise.all(ids.map(
            async id => await api.deleteFile(id)
        )).then(() => {
            let files = state.files.filter(file => !ids.includes(file.id))
            commit('SET_FILES', files)
        })
    },
    async dropFile({commit}, fileId) {
        return await api.deleteFile(fileId).then(() => {
            let files = state.files.filter(file => file.id !== fileId)

            commit('SET_FILES', files)
        })
    }
};

const mutations = {
    SET_FILES(state, files) {
        state.files = files;
    },
    SET_TOTAL(state, total){
        state.total = total;
    },
    SET_LOADED(state, loaded) {
        state.loaded = loaded;
    },
};

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}

