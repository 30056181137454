import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Home';
import auth from "@/auth";
import store from "@/store";
import {ADMIN_PLATFORM_URL} from "@/const"


const router = createRouter({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                authRequired: true,
                title: 'FSMS',
            }
        },
        {
            path: '/oauth2/accesstoken',
            redirect: to => {
                const {hash} = to;
                auth.setTokenHash(hash);
                // console.log(auth.getStoredPath());
                return {
                    path: auth.getStoredPath(),
                    hash: ''
                }
            }
        },
        {
            path: '/oauth2/accesstoken/implicit',
            redirect: to => {
                const {hash} = to;
                auth.setTokenHash(hash);

                // console.log(hash);

                return {
                    path: '/',
                    hash: '',
                }
            }
        },
        {
            path: '/logout',
            redirect: () => {
                auth.removeAccessToken()

                window.location = ADMIN_PLATFORM_URL + '/logout';
            }
        }

    ],
    history: createWebHistory('/'),
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        (async () => {
            // alert(auth.isAuthenticated());
            if (!auth.isAuthenticated()) {
                await auth.authenticate(to.path);
            }

            if (!await auth.isAuthorized(to.path)) {
                auth.authorizeError();

                return;
            }

            // await store.dispatch('locales/loadOnce');
            if (!store.state.user.loaded) {
                await store.dispatch('user/load')
            }

            if (!store.state.brands.loaded) {
                await store.dispatch('brands/load')
            }

            if (store.state.filter.mode === 'all' && !store.state.users.loaded) {
                await store.dispatch('users/loadUsers')
            }

            if (!store.state.files.loaded) {
                let brands = store.state.brands.brands

                if (brands.length === 1) {
                    await store.dispatch('filter/setBrand', brands[0]['id'])
                }

                await store.dispatch('files/load', store.state.filter)
            }

            document.title = to.meta.title;
            next();

        })()
    } else {
        document.title = to.meta.title;
        next()
    }
});


export default router


