<template>
    <div class="card-uploading">
    <a-card  v-if="filesCount > 0 && show">
        <template #title>
            <div class="loader" v-if="uploadingFilesCount > 0"></div>
            <span class="loader-icon" v-else>
                <img src="/img/file_uploading_failed.svg" class="uploading-title-icon" v-if="errorFilesCount > 0">
                <img src="/img/file_uploaded.svg" class="uploading-title-icon" v-else>
            </span>

            <span class="files-uploading-header">{{ filesUploadingTitle }}</span>
        </template>

        <template #extra>
            <down-outlined :style="downOutlinedStyles" @click="toggleMinimize"/>
            <close-outlined style="color: #fff; padding: 0 5px 0 0;" @click="close"/>
        </template>

        <div class="files_list" v-if="!minimized">
            <a-row v-for="file in files" :key="file.uid">
                <a-col :span="3" v-if="file.status === 'uploading'">
                    <div class="img-preview upload">
                        <loading-outlined type="loading" />
                    </div>
                    <div class="loader file"></div>
                </a-col>
                <a-col :span="3" v-if="file.status === 'error'">
                    <div>
                        <file-exclamation-outlined :style="{ fontSize: '26px', color: '#ccc' }"/>
                    </div>
                    <span class="file-status-icon">
                        <img src="/img/file_uploading_failed.svg" alt="">
                    </span>
                </a-col>
                <a-col :span="3" v-if="file.status === 'done'">
                    <div v-if="isImage(fileExt(file))" class="img-preview upload">
                        <img :src="fileUri(file)" alt="thumb">
                    </div>
                    <div v-else-if="isPdf(fileExt(file))">
                        <file-pdf-outlined :style="{ fontSize: '26px' }"/>
                    </div>
                    <div v-else-if="isZip(fileExt(file))">
                        <file-zip-outlined :style="{ fontSize: '26px' }" />
                    </div>
                    <div v-else-if="isWord(fileExt(file))">
                        <file-word-outlined :style="{ fontSize: '26px' }"/>
                    </div>
                    <div v-else-if="isXls(fileExt(file))">
                        <file-excel-outlined :style="{ fontSize: '26px' }" />
                    </div>
                    <div v-else-if="isText(fileExt(file))">
                        <file-text-outlined :style="{ fontSize: '26px' }" />
                    </div>
                    <div v-else>
                        <file-outlined style="{ fontSize: '26px' }"/>
                    </div>
                    <span class="file-status-icon">
                        <img src="/img/file_uploaded.svg" alt="">
                    </span>

                </a-col>
                <a-col :span="15">
                    <p class="filename">{{file.name}}</p>
                    <p class="filesize">{{ $filters.humanReadableSize(file.size) }}</p>
                </a-col>
                <a-col :span="6">
                    <p class="file-status" :style="fileStatusStyle">
                        {{ t(file.status) }}
                    </p>
                </a-col>
            </a-row>
        </div>

    </a-card>
    </div>
</template>


<script>
import {Card, Col, Row} from "ant-design-vue";
import {CloseOutlined, DownOutlined, LoadingOutlined} from "@ant-design/icons-vue";
import {
    FilePdfOutlined,
    FileZipOutlined,
    FileTextOutlined,
    FileExcelOutlined,
    FileWordOutlined,
    FileOutlined,
    FileExclamationOutlined,
} from '@ant-design/icons-vue';
import {mapGetters, mapMutations} from "vuex";

export default {
    components: {
        'a-card': Card,
        'a-col': Col,
        'a-row': Row,
        DownOutlined,
        CloseOutlined,
        LoadingOutlined,
        FilePdfOutlined,
        FileZipOutlined,
        FileTextOutlined,
        FileExcelOutlined,
        FileWordOutlined,
        FileOutlined,
        FileExclamationOutlined,
    },
    data() {
        return {
            minimized: true,
            show: true
        }
    },
    computed: {
        ...mapGetters('files', ['isImage', 'isText', 'isZip', 'isPdf', 'isWord', 'isXls']),
        ...mapGetters('uploading_files', ['files', ]),
        ...mapGetters('translations', ['t', ]),
        filesUploadingTitle() {
            let files = this.spelling('file', this.filesCount)
            if (this.filesCount === this.uploadingFilesCount) {
                return this.t('Uploading %s ' + files, this.filesCount);
            } else {
                let errors = this.spelling('error', this.errorFilesCount)
                return this.t('Uploaded %s of %s '+ files, [this.successFilesCount,  this.filesCount]) +
                    (this.errorFilesCount > 0 ? (' - ' + this.t('n ' + errors, String(this.errorFilesCount))) : '' );
            }
        },
        filesCount() {
            return this.files.length;
        },
        uploadingFilesCount() {
            return this.files.filter(file => { return file.status === 'uploading'}).length
        },
        errorFilesCount() {
            return this.files.filter(file => { return file.status === 'error' }).length
        },
        successFilesCount() {
            return this.files.filter(file => { return file.status === 'done' }).length
        },
        downOutlinedStyles() {
            return {
                color: '#fff',
                transform: this.minimized ? 'rotate(180deg)' : 'rotate(0)',
                padding: '0 5px',
            }
        }
    },
    methods: {
        ...mapMutations('uploading_files', ['RESET_FILES']),
        fileStatusStyle(file) {
            if (file.status === 'error') {
                return {'color': '#FF4D4F',}
            }
            return {};
        },
        fileUri(file) {
            return '/' + file.response.uri
        },
        fileExt(file) {
            let match = file ? file.response.uri.match(/\.([^.]{3,4})$/) : [];
            return match ? match[1] : '';
        },
        spelling(word, count) {
            if (count !== 1) {
                return word + 's';
            }
            return word;
        },
        toggleMinimize() {
            this.minimized = !this.minimized;
        },
        close() {
            this.RESET_FILES()
        }
    },
}

</script>



<style lang="less">
div.card-uploading {
    z-index: 1001;
    width: 350px;
    position: fixed;
    bottom: 30px;
    left: calc(100% - 380px);
}
.ant-card-head {
    background: rgba(0, 0, 0, 0.65)!important;
    color: #fff!important;
    height: 40px!important;
    min-height: 40px!important;
    line-height: 1;
    font-size: 14px;
}
.ant-card .ant-card-body {
    max-height: 310px;
    overflow: auto;
}

.ant-card-head-title {
    padding: 0!important;
}

.ant-card-extra  {
    .anticon {
        font-size: 14px;
        color: #fff!important;
    }
    padding: 12px 0!important;
}

.files-uploading-header {
    font-size: 14px;
}

span.file-status-icon {
    position: absolute;
    height: 12.5px;
    width: 12.5px;
    top: 22px;
    right: 5px;
    background-color: white;
    border-radius: 13px 13px 13px 13px;

    img {
        margin: 0.4px;
        position: absolute;
        width: 12px;
        height: 12px;
    }
}

.uploading-title-icon {
    margin-right: .5rem;
    //vertical-align: baseline;
}

.loader {
    display: inline-block;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    //border-left: 2px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 2s linear infinite;
    margin-right: .8rem;
}


.loader.file {
    position: absolute;
    top: 25px;
    right: -7px;
    height: 10px;
    width: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.container-fluid {
    padding-right: var(--bs-gutter-x, 2rem)!important;
    padding-left: var(--bs-gutter-x, 2rem)!important;
}

.ant-card {

    .ant-card-head {
        padding: 0 12px;

        .ant-card-extra {
            span {
                cursor: pointer;
            }
        }

        .anticon {
            vertical-align: 0;
        }
    }

    .ant-card-body {
        padding: 0 12px!important;

        .files_list {
            padding: 12px 0;
        }


        .filename {
            margin: 0;
            font-size: 14px;
            line-height: 16px;
            overflow: hidden;
            margin-right: 1rem;
        }
        .filesize {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 16px;
        }

        .file-status {
            text-align: right;
            font-size: 14px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.65);
            text-transform: capitalize;

        }

    }

    .ant-card-body {
        padding:12px;
    }

    .title {
        padding: 1rem .5rem;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 2px 2px 0 0;
        color: #fff;

        p {
            margin: 0;
        }
    }
}


</style>



