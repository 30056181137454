import _ from "lodash"
import api from '../api'

const state = {
    loaded: false,
    brands: [],
};

const getters = {
    brands(state) {
        return state.brands
    },
};

const actions = {
    async load({commit}) {
        const response = await api.getBrands()

        commit('SET_BRANDS', response.data.brands ?? []);
        commit('SET_LOADED', true);
    },
};

const mutations = {
    SET_BRANDS(state, brands) {
        state.brands = _.cloneDeep(brands)
    },
    SET_LOADED(state, loaded) {
        state.loaded = loaded
    },
};

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}

