import {createStore} from 'vuex'
import user from './modules/user'
import translations from './modules/translations'
import locales from './modules/locales'
import uploading_files from './modules/uploading_files'
import users from './modules/users'
import brands from './modules/brands'
import files from './modules/files'
import filter from './modules/filter'

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
    state:     {},
    modules:   {
        user, translations, locales, uploading_files, files, brands, users, filter
    },
    mutations: {},
    actions:   {},
    strict:    debug
})

export default store;