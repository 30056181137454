import axios from 'axios'
import store from './index'
import router from '../router'
import {ADMIN_PLATFORM_URL, CLIENT_ID} from "@/const"
import {sessionStorage} from "./web-storage";
import auth from '../auth';
import qs from 'qs';
import { notification } from 'ant-design-vue';
import { FrownOutlined } from '@ant-design/icons-vue'

// create an axios instance with default options
const http = axios.create();

const setAccessToken = (token) => {
    http.defaults.headers.common['Authorization'] = 'Bearer ' + token
};

const getAccessToken = () => http.defaults.headers.common['Authorization'];

// initial accessToken stored in sessionStorage and must be used by api
const accessToken = JSON.parse(sessionStorage.getItem('access-token')) !== null ?
                    JSON.parse(sessionStorage.getItem('access-token'))['token'] : null;

if (accessToken) {
    setAccessToken(accessToken);
}

http.interceptors.request.use(function (config) {
    // store.commit('loading', true);
    // Do something before request is sent
    return config;
});

http.interceptors.response.use(function (response) {
    // store.commit('loading', false);

    // Do something with response data
    return response;
}, function (error) {
    requestFailed(error);
    return Promise.reject(error.response);
});

const requestFailed = (error) => {
    let path = router.currentRoute.value.path
    if (error.config && error.response && error.response.status === 401 && path !== '/oauth2/accesstoken' && path != '/oauth2/accesstoken/implicit') {
        if (auth.authenticate(path)) {
            error.config.headers['Authorization'] = http.defaults.headers.common['Authorization'];
            return axios.request(error.config);
        }
    }

    if (error.response.status !== 401) {
        notification.open({
            message: error.response.statusText,
            description: error.response.data.error,
            icon: <FrownOutlined />,
        });

        store.commit('files/SET_TOTAL', 0)
        store.commit('files/SET_FILES', [])
        store.commit('files/SET_LOADED', true)
    }
};


export default {
    setAccessToken:            setAccessToken,
    getAccessToken:            getAccessToken,
    getUser:                   (locale = '') => http.get(ADMIN_PLATFORM_URL + '/api/v2/current-user-data?locale=' + locale),
    getLayout:                 () => http.get(ADMIN_PLATFORM_URL + '/api/v2/layout'),
    getAccess:                 (path) => {
        const query = qs.stringify({
            client_id: CLIENT_ID,
            path:      path
        });
        return http.get(ADMIN_PLATFORM_URL + '/api/v2/access?' + query)
    },
    deleteFile:                (fileId) => http.delete(`/admin/file/${fileId}`),
    replaceFile:               (fileId, file) => {
        let formData = FormData();
        formData.append("file", file)
        http.post(`/admin/replace/${fileId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    getBrands:                 () => http.get('/admin/brands'),
    getUsers:                  () => http.get('/admin/users'),
    getFiles: (filter) => {
        const query = qs.stringify(filter);

        return http.get(`/admin/files?` + query)
    },
};