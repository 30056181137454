import {vsprintf} from "sprintf-js";

const state = {
    items:  [],
    loaded: false
};

const actions = {
    load({commit}, locale) {
        if (locale === 'ru_RU') {
            commit('update', {
                'Download': 'Скачать',
                'Delete': 'Удалить',
                'My files': 'Мои файлы',
                'All files': 'Все файлы',
                'Uploading %s file': 'Загружка %s файла',
                'Uploading %s files': 'Загрузка %s файлов',
                'Uploaded %s of %s files': 'Загружено %s из %s файлов',
                'Uploaded %s of %s file': 'Загружено %s из %s файлов',
                'n errors': '%s ошибок',
                'n error': '%s ошибка',
                'Yes': 'Да',
                'No': 'Нет',
                'Uploaded': 'Загружено',
                'Action': 'Действия',
                'Search by name': 'Поиск по имени',
                'All brands': 'Все бренды',
                'All users': 'Все пользователи',
                'Name': 'Имя',
                'URL': 'URL',
                'Author': 'Автор',
                'Show': 'Показать',
                'Brand': 'Бренд',
                'Preview': 'Предпросмотр',
                'Replace': 'Заменить',
                'Users': 'Пользователи',
                'Actions': 'Действия',
                'File storage': 'Файловое хранилище',
                'Click or drag file to this area to upload': 'Кликните или перенесите файл в эту область для загрузки',
                'Files of any format, up to 100 MB': 'Файлы любого формата, до 100 МБ',
                'Size': 'Размер',
                'Format': 'Формат',
                'Type': 'Тип',
                'kB': 'кБ',
                'MB': 'МБ',
                'TB': 'ТБ',
                'Are you sure want to delete this files?': 'Вы действительно хотите удалить эти файлы?',
                'Are you sure want to delete this file?': 'Вы действительно хотите удалить этот файл?',
                'done': 'загружен',
                'error': 'ошибка',
                'loading': 'загружается',
                'uploading': 'загрузка',
                'Copy url': 'Скопировать url',
                'Url copied!': 'Url скопирован!',
                'The new file is over size 100 MB': 'Новый файл превышает размер 100 МБ',
                'Uploading': 'Загрузка'
            })
        } else {
            commit('update', {
                'Download': 'Download',
                'Delete': 'Delete',
                'My files': 'My files',
                'All files': 'All files',
                'Uploading %s file': 'Uploading %s file',
                'Uploading %s files': 'Uploading %s files',
                'Uploaded %s of %s files': 'Uploaded %s of %s files',
                'Uploaded %s of %s file': 'Uploaded %s of %s file',
                'n errors': "%s errors",
                'n error': "%s error",
                'Yes': 'Yes',
                'No': 'No',
                'Uploaded': 'Uploaded',
                'Action': 'Action',
                'Search by name': 'Search by name',
                'All brands': 'All brands',
                'All users': 'All users',
                'Name': 'Name',
                'URL': 'URL',
                'Author': 'Author',
                'Show': 'Show',
                'Brand': 'Brand',
                'Preview': 'Preview',
                'Replace': 'Replace',
                'Users': 'Users',
                'Actions': 'Actions',
                'File storage': 'File storage',
                'Click or drag file to this area to upload': 'Click or drag file to this area to upload',
                'Files of any format, up to 100 MB':'Files of any format, up to 100 MB',
                'Size': 'Size',
                'Format': 'Format',
                'Type': 'Type',
                'kB': 'kB',
                'MB': 'MB',
                'TB': 'TB',
                'Are you sure want to delete this files?':'Are you sure want to delete this files?',
                'Are you sure want to delete this file?':'Are you sure want to delete this file?',
                'done': 'done',
                'error': 'error',
                'loading': 'loading',
                'uploading': 'uploading',
                'Copy url': 'Copy url',
                'Url copied!': 'Url copied!',
                'The new file is over size 100 MB': 'The new file is over size 100 MB',
                'Uploading': 'Uploading'
            });
        }

        commit('loaded')
    }
};

const mutations = {
    update(state, items) {
        state.items = items
    },
    loaded(state) {
        state.loaded = true
    }
};

const getters = {
    t: state => (key, args = null) => {
        if (state.items[key]) {
            return args ? vsprintf(state.items[key], args) : state.items[key]
        } else {
            console.warn('translation for key "' + key + '" not found')
            return key;
        }
    },

};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}