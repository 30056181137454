import _ from 'lodash'
import api from '../api'
import {ACCESS_PATH} from "@/const";

// initial state
const state = {
    loaded:  false,
    layout:  {},
    current: {},
    access:  {},
};
// default
state.access[ACCESS_PATH] = {'write': false}

const getters = {
    getFlagUrl:              state => flag => {
        let url = _.get(state, 'layout.templateBaseUrl', '');

        return url ?
            url + 'assets/images/flags/' + flag + '.svg' : ''
    },
    getCurrentFlagUrl:       (state, getters) => {
        let locales = _.get(state, 'layout.locales', []);
        let locale = locales.find(x => x.name === getters.getLocale);

        return locale ? getters.getFlagUrl(locale.flag) : ''
    },
    getLocale:               state => _.get(state, 'current.locale', 'en_US'),
    getLocaleDateFormat:     state => {
        return state.current.locale == 'ru_RU' ? 'DD.MM.YYYY' : 'YYYY-MM-DD'
    },
    getLocaleDateTimeFormat: state => {
        return state.current.locale == 'ru_RU' ? 'DD.MM.YYYY HH:mm' : 'YYYY-MM-DD HH:mm'
    },
    getHomeUrl:              state => _.get(state, 'layout.commonPages.homeUrl', ''),
    getCurrentMenu: state => _.get(state, 'current.menu'),
    getCurrentUserId: state => _.get(state, 'current.id'),
};

const actions = {
    async changeLocale({commit, dispatch}, locale) {
        let date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        let expires = date.toUTCString();
        let name = state.layout.localeCookieName;
        let domain = state.layout.cookieDomain;

        document.cookie = name + "=" + locale + ";domain=" + domain + ";expires=" + expires + ";path=/";

        commit('LOCALE', locale);

        // parallel flow
        dispatch('translations/load', locale, {root: true});
        let user = await api.getUser(locale);

        commit('CURRENT', user.data.data);
    },
    async load({commit, dispatch, state}) {
        // parallel flow
        let loadLayout = api.getLayout();
        let loadUser = api.getUser();

        let layout = await loadLayout;
        commit('LAYOUT', layout.data.data);

        let user = await loadUser;
        commit('CURRENT', user.data.data);
        commit('LOADED', true);

        dispatch('translations/load', state.current.locale, {root: true});
    },
    async loadAccess({commit}, {path}) {
        let response = await api.hasAccess(path)
        commit('ACCESS', {path: path, permissions: response.data.data})
    }
};

const mutations = {
    LOCALE(state, locale) {
        state.current.locale = locale
    },
    CURRENT(state, current) {
        state.current = current
    },
    LAYOUT(state, layout) {
        state.layout = layout
    },
    LOADED(state, loaded) {
        state.loaded = loaded
    },
    ACCESS(state, {path, permissions}) {
        state.access[path] = permissions;
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
