const PROTOCOL = window.location.protocol;

export const BASE_URL = PROTOCOL + window.BASE_URL;
export const ADMIN_PLATFORM_URL = PROTOCOL + window.ADMIN_PLATFORM_URL;

export const CLIENT_ID = 'fsms-new';

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'; // RFC 3339
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const ALL = "All";

export const ACCESS_PATH = '/';

export const FILE_LIMIT = 1e+8; // лимит указан в байтах


