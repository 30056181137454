import _ from "lodash"
import api from '../api'

const state = {
    loaded: false,
    users: [],
};

const getters = {
    users(state) {return state.users},
    usersLoaded(state) {return state.loaded}
};

const actions = {
    async loadUsers({commit}) {
        const response = await api.getUsers()

        commit('SET_USERS', response.data.users);
        commit('SET_LOADED', true);
    },
};

const mutations = {
    SET_USERS(state, users) {
        state.users = _.cloneDeep(users)
    },
    SET_LOADED(state, loaded) {
        state.loaded = loaded
    },
};

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}

