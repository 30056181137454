<template>
    <div>
        <h1>
            {{ t('File storage') }}
        </h1>

        <a-row :gutter="[0,20]" justify="space-between">
            <a-col :span="24">

                <a-upload-dragger
                    name="file"
                    :multiple="true"
                    action="/admin/upload"
                    :showUploadList="false"
                    :headers="{Authorization: token}"
                    :beforeUpload="beforeUpload"
                    :disabled="!this.allowMyFilesUpload"
                    @change="handleChange">

                        <div class="dragger">
                            <img src="/img/upload.svg">

                            <h3>{{ t('Click or drag file to this area to upload') }}</h3>
                            <h6>
                                {{ t('Files of any format, up to 100 MB') }}
                            </h6>
                        </div>

                </a-upload-dragger>
            </a-col>

        <a-col :span="24" style="margin-top: 2rem;">
            <files-list></files-list>
        </a-col>

        </a-row>
    </div>

</template>

<script>
import { Upload, Col, Row, } from 'ant-design-vue';
import { mapActions, mapGetters } from "vuex";
import List from '../components/files/List';
import api from '../store/api'
import { FILE_LIMIT } from "@/const";
import {hasAccess, CREATE} from "@/access";

export default {
    data() {
        let token = api.getAccessToken()

        return {
            token: token,
            allowMyFilesUpload: false,
        }
    },
    created() {
        hasAccess("/", CREATE).then(
            (allowed) => {
                this.allowMyFilesUpload = allowed

            }
        )
    },
    components: {
        'a-upload-dragger': Upload,
        'a-row': Row,
        'a-col': Col,
        'files-list': List,
    },
    computed: {
        ...mapGetters('files', ['files']),
        ...mapGetters('filter', ['filter']),
        ...mapGetters('users', ['users']),
        ...mapGetters('brands', ['brands']),
        ...mapGetters('translations', ['t']),
    },
    methods: {
        ...mapActions('uploading_files', ['setFiles', ]),
        ...mapActions('files', ['load']),
        handleChange(info) {
            let fileList = [...info.fileList]

            this.setFiles(fileList);
            if (fileList.filter(file => file.status === 'done' || file.status === 'error').length === fileList.length) {
                this.load(this.filter)
            }

        },
        beforeUpload(file) {
            if (file.size > FILE_LIMIT) {
                file.status = 'error';
                return false;
            }
        }
    }
}

</script>


<style lang="scss">

.router-view h1 {
    margin: 2rem 0;
    font-size: 2.2rem;

}

.ant-upload {
    width: 100%;

    .dragger {
        border: 1px solid #D9D9D9;
    }
}

.dragger {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0px;

    border: 1px solid #FAFAFA;
    background: #FAFAFA;
    box-sizing: border-box;
    border-radius: 2px;

    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        margin: 1rem 0 .5rem;
    }

    h6 {
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: rgba(0, 0, 0, 0.45);
        margin: 0 0 .5rem;
    }

    &:hover {
        border: 1px solid #1890FF;
    }

}




</style>