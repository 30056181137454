import qs from 'qs'
import {ADMIN_PLATFORM_URL, BASE_URL} from "./const"
import {localStorage, sessionStorage} from './store/web-storage'
import api from './store/api';
import _ from 'lodash'

const TOKEN_NAME = 'access-token';
const CLIENT_ID = 60;
const REDIRECT_URL = BASE_URL + '/oauth2/accesstoken';

const getImplicitGrantUrl = (isImplicit) => {
    const redirect = isImplicit ? REDIRECT_URL + '/implicit' : REDIRECT_URL;
    let query = qs.stringify({
        response_type: 'token',
        client_id:     CLIENT_ID,
        redirect_uri:  redirect,
    });

    return ADMIN_PLATFORM_URL + '/api/v2/authorize?' + query;
};

const isAuthenticated = () => {
    // console.log(hasAccessToken(), isTokenExpired());
    return hasAccessToken() && !isTokenExpired();
};

const hasAccessToken = () => {
    // console.log('token in session storage');
    // console.log(sessionStorage.getItem(TOKEN_NAME));
    return sessionStorage.getItem(TOKEN_NAME) !== null
};

const isTokenExpired = () => {

    let token = JSON.parse(sessionStorage.getItem(TOKEN_NAME));
    let date = new Date();

    return token && token.expired ? token.expired <= date.getTime() : true
};

let authenticatePromise;
const isAuthenticateInProcess = () => {
    return (typeof authenticatePromise == 'undefined' || authenticatePromise === false)? false : true;
};

const authenticate = async (routePath) => {
    setStoredPath(routePath);
    if (!isAuthenticateInProcess()) {
        authenticatePromise = new Promise((resolve) => {
            implicitTokenUpdate().then(tokenUpdated => {
                if (!tokenUpdated) {

                    document.location = getImplicitGrantUrl();
                }
                resolve(true);
            });
        });
    }

    let result = await authenticatePromise;
    authenticatePromise = false;
    return result;
};

const isAuthorized = async (routePath) => {
    let authorized = await api.getAccess(routePath);

    return !!_.get(authorized, 'data.data.hasAccess', false);
};

const authorizeError = () => {
    document.location = ADMIN_PLATFORM_URL + '/error/403';
};

const setAccessToken = (token) => {
    sessionStorage.setItem(TOKEN_NAME, JSON.stringify(token));

    api.setAccessToken(token.token)

};

const removeAccessToken = () => {
    sessionStorage.setItem(TOKEN_NAME, null);
}

const parseTokenHash = (hash) => {
    let params = {};
    hash.replace(/^#/, '').split('&').map(keyVal => {
        let temp = keyVal.split('=');
        params[temp[0]] = temp[1]
    });

    let date = new Date();

    return {
        token:   params.access_token,
        type:    params.token_type,
        expired: date.getTime() + Number(params.expires_in) * 1000
    };
};

const setTokenHash = (hash) => {
    let token = parseTokenHash(hash);
    setAccessToken(token)
};


const implicitTokenUpdate = async () => {
    document.location = getImplicitGrantUrl(true);

    return await new Promise(resolve => {
        resolve(true);
    });
};

const setStoredPath = (path) => {
    localStorage.setItem('path', path);
};

const getStoredPath = () => {
    let path = localStorage.getItem('path');
    return path ? path : '/';
};

export default {
    authenticate,
    isAuthenticated,
    isAuthorized,
    authorizeError,
    setTokenHash,
    getStoredPath,
    isAuthenticateInProcess,
    removeAccessToken,
};
