<template>

    <div id="app">
        <layout></layout>
    </div>

</template>


<script>
import Layout from './components/Layout';

export default {
    components: {
        'layout': Layout,
    },
}

</script>

<style lang="scss">

@import "bootstrap";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,100,500,700,900');
@import "../public/css/icomoon.css";
@import "../public/css/icons-iq.css";

</style>

<style lang="less">
@import "assets/less/base.less";

</style>


