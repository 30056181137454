<template>

    <a-row :gutter="[10,20]" justify="start">

        <a-col :span="18">
            <a-row justify="start">
                <a-col class="filter-col" v-if="allowAllFilesRead">
                    <p class="filter-label">{{ t('Show') }}</p>
                    <a-radio-group @change="onChangeMode" :default-value="filter.mode">
                        <a-radio-button class="filter-files-owner" value="my">{{ t('My files') }}</a-radio-button>
                        <a-radio-button class="filter-files-owner" value="all">{{ t('All files') }}</a-radio-button>
                    </a-radio-group>
                </a-col>

                <a-col class="filter-col">
                    <p class="filter-label">{{ t('Search by name') }}</p>
                    <a-input v-model:value="search" @change="debounceSearch" class="search">
                        <template #addonAfter>
                            <search-outlined/>
                        </template>
                    </a-input>
                </a-col>

                <a-col class="filter-col" v-if="brands.length > 1">
                    <p class="filter-label">{{ t('Brand') }}</p>
                    <a-select
                        @change="onChangeBrand"
                        class="select-brand"
                        :defaultValue="filter.brand_id"
                        :placeholder="t('All brands')">
                        <a-select-option :value="null" v-bind:key="null">
                            <span>{{ t('All brands') }}</span>
                        </a-select-option>
                        <a-select-option v-for="brand in brands" :value="brand.id" v-bind:key="brand.id">
                            {{ brand.name }}
                        </a-select-option>
                    </a-select>
                </a-col>

                <a-col class="filter-col" v-if="filter.mode === 'all'">
                    <p class="filter-label">{{ t('Users') }}</p>
                    <a-select
                        class="select-user"
                        @change="onChangeUser"
                        :defaultValue="filter.user_id"
                        :loading="!usersLoaded"
                        :placeholder="t('All users')">
                        <a-select-option :value="null" v-bind:key="null">
                            <span>{{ t('All users') }}</span>
                        </a-select-option>
                        <a-select-option v-for="user in users" :value="user.id" :key="user.id">
                            {{ user.name }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </a-col>

        <a-col :span="6" style="text-align: right; margin-top: auto;" v-show="selectedRows.length"
               class="actions-buttons">
            <a-button style="margin-right: 1rem;" @click="downloadSelectedFiles">
                <download-outlined/>
                {{ t('Download') }}
            </a-button>
            <a-popconfirm v-if="filter.mode === 'my' || allowAllFilesDelete" placement="topLeft"
                          :ok-text="t('Yes')"
                          :cancel-text="t('No')" @confirm="deleteSelectedFiles">
                <template #icon>
                    <exclamation-circle-outlined style="color: #FAAD14!important;"/>
                </template>
                <template #title>
                    <p class="confirm-text">{{ t('Are you sure want to delete this files?') }}</p>
                </template>
                <a-button>
                    <delete-outlined/>
                    {{ t('Delete') }}
                </a-button>
            </a-popconfirm>
        </a-col>
    </a-row>

    <a-row style="margin-top: 2rem;">
        <a-col :span="24">
            <a-table :data-source="files" :loading="!fileLoaded" @change="sorter"
                     :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                     :pagination="{
                          current: getPage,
                          showSizeChanger: true,
                          onChange: onChangePage,
                          onShowSizeChange: onShowSizeChange,
                          total: total
                     }"
                     row-key="id">
                <a-table-column key="preview" :title="t('Preview')" data-index="preview" class="nowrap-text"
                                width="10%">
                    <template #default="file">
                        <div v-if="isImage(file.record.params.format)" class="img-preview"
                             @click="showPreview(file.record)">
                            <img :src="file.record['file_uri']"/>
                        </div>
                        <div v-else-if="isPdf(file.record.params.format)">
                            <file-pdf-outlined :style="{ fontSize: '32px' }"/>
                        </div>
                        <div v-else-if="isZip(file.record.params.format)">
                            <file-zip-outlined :style="{ fontSize: '32px' }"/>
                        </div>
                        <div v-else-if="isWord(file.record.params.format)">
                            <file-word-outlined :style="{ fontSize: '32px' }"/>
                        </div>
                        <div v-else-if="isXls(file.record.params.format)">
                            <file-excel-outlined :style="{ fontSize: '32px' }"/>
                        </div>
                        <div v-else-if="isText(file.record.params.format)">
                            <file-text-outlined :style="{ fontSize: '32px' }"/>
                        </div>
                        <div v-else>
                            <file-outlined :style="{ fontSize: '32px' }"/>
                        </div>
                    </template>
                </a-table-column>

                <a-table-column key="name" :title="t('Name')"
                                data-index="name">
                        <template #default="file">
                                <div class="ellipsis-long-text">
                                    <a-tooltip placement="top" color="blue" :title="file.record['orig_file_name']">
                                        {{ file.record['orig_file_name'] }}
                                    </a-tooltip>
                                </div>
                        </template>
                </a-table-column>
                <a-table-column key="url" :title="t('URL')" data-index="url">
                    <template #default="file">
                        <div class="text-nowrap">
                            <div class="ellipsize-left">
                                <a :href="file.record['file_uri']" target="_blank" class="url">
                                    {{ file.record['file_uri'] }}
                                </a>
                            </div>
                            <div class="copy-block">
                                <a class="copy" @click="copyUrl(file.record['file_uri'])">
                                    <a-tooltip>
                                        <template #title v-if="file.record['file_uri'] !== urlCopied">
                                            {{ t('Copy url') }}
                                        </template>
                                        <template #title v-else>
                                            {{ t('Url copied!') }}
                                        </template>
                                        <copy-outlined/>
                                    </a-tooltip>
                                </a>
                            </div>
                        </div>
                    </template>
                </a-table-column>
                <a-table-column key="user" :title="t('Author')" data-index="user"/>
                <a-table-column key="uploaded" :title="t('Uploaded')"
                                :sorter="true"
                                default-sort-order="descend"
                                :sortDirections="['ascend', 'descend', 'ascend']"
                                data-index="created">
                    <template #default="file">
                        {{ formatDateTime(file.record.created) }}
                    </template>
                </a-table-column>
                <a-table-column key="action" :title="t('Actions')" class="nowrap-text" width="15%">
                    <template #default="file">

                        <a-tooltip>
                            <template #title>
                                <ul id="info" v-if="isShowFileInfo(file.record)">
                                    <li v-for="(item, index) in file.record.params" v-show="item" :key="item">
                                        {{ formatLabelInfo(index) }}:
                                        <template v-if="index == 'size'">{{
                                                $filters.humanReadableSize(item)
                                            }}
                                        </template>
                                        <template v-else>{{ item }}</template>
                                    </li>
                                </ul>

                                <p class="tooltip-text" v-else>Info</p>
                            </template>

                            <info-circle-outlined @click="toggleShowFileInfo(file.record)"/>
                        </a-tooltip>

                        <a-upload
                            v-if="this.allowFilesEdit(file.record.user_id)"
                            name="file"
                            :showUploadList="false"
                            :action="getReplaceUrl(file.record.id)"
                            :onChange="onChangeUpload"
                            :beforeUpload="beforeUpload"
                            :headers="{Authorization: token}">
                            <a-tooltip>
                                <template #title>
                                    {{ t('Replace') }}
                                </template>
                                <retweet-outlined/>
                            </a-tooltip>
                        </a-upload>

                        <a-popconfirm v-if="this.allowFilesDelete(file.record.user_id)" placement="topLeft"
                                      :ok-text="t('Yes')"
                                      :cancel-text="t('No')" @confirm="deleteFile(file.record.id)">
                            <template #icon>
                                <exclamation-circle-outlined style="color: #FAAD14!important;"/>
                            </template>
                            <template #title>
                                <p class="confirm-text">
                                    {{ t('Are you sure want to delete this file?') }}
                                </p>
                            </template>
                            <a-tooltip>
                                <template #title>
                                    {{ t('Delete') }}
                                </template>
                                <delete-outlined/>
                            </a-tooltip>
                        </a-popconfirm>

                    </template>
                </a-table-column>
            </a-table>

        </a-col>
        <a-modal v-model:visible="previewModalVisible" centered :footer="null" wrapClassName="modal-img-preview">
            <template #closeIcon>
                <close-outlined
                    style="position: absolute; top: -25px; right:-35px; color:#fff!important; font-size: 25px;"/>
            </template>

            <img :src="previewUrl" alt="preview">

        </a-modal>
    </a-row>
</template>

<script>
import {
    CloseOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined,
    FilePdfOutlined,
    FileZipOutlined,
    FileTextOutlined,
    FileExcelOutlined,
    FileWordOutlined,
    FileOutlined,
    InfoCircleOutlined,
    RetweetOutlined,
    SearchOutlined,
} from '@ant-design/icons-vue';
import {
    Button,
    Col,
    Input,
    Modal,
    Popconfirm,
    RadioButton,
    RadioGroup,
    Row,
    Select,
    SelectOption,
    Table,
    TableColumn,
    Tooltip,
    Upload,
} from 'ant-design-vue';
import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from "moment";
import api from "@/store/api";
import {DELETE, hasAccess, READ, WRITE} from "@/access";
import {FILE_LIMIT} from "@/const";
import { notification } from 'ant-design-vue';
import { FrownOutlined } from '@ant-design/icons-vue'

export default {
    components: {
        'a-table':         Table,
        'a-table-column':  TableColumn,
        'a-popconfirm':    Popconfirm,
        'a-button':        Button,
        'a-radio-group':   RadioGroup,
        'a-radio-button':  RadioButton,
        'a-col':           Col,
        'a-row':           Row,
        'a-input':         Input,
        'a-select':        Select,
        'a-select-option': SelectOption,
        'a-modal':         Modal,
        'a-tooltip':       Tooltip,
        'a-upload':        Upload,
        RetweetOutlined, InfoCircleOutlined, DeleteOutlined, ExclamationCircleOutlined,
        SearchOutlined, CloseOutlined, CopyOutlined, DownloadOutlined, FilePdfOutlined,
        FileZipOutlined, FileTextOutlined, FileExcelOutlined, FileOutlined, FileWordOutlined
    },
    mounted() {
    },
    data() {
        let token = api.getAccessToken()

        return {
            previewModalVisible: false,
            previewUrl:          '',
            selectedBrands:      [],
            selectedRows:        [],
            selectedRowKeys:     [],
            selectedRowsMem:     {},
            showFileInfo:        {},
            token:               token,
            search:              '',
            urlCopied:           '',
            allowAllFilesRead:   false,
            allowAllFilesEdit:   false,
            allowAllFilesDelete: false,
            allowMyFilesEdit: false,
            allowMyFilesDelete: false,
        }
    },
    created() {
        hasAccess("/admin/all", READ).then(
            (allowed) => {
                this.allowAllFilesRead = allowed
                hasAccess("/admin/all", WRITE).then((allowed) => this.allowAllFilesEdit = allowed)
                hasAccess("/admin/all", DELETE).then((allowed) => this.allowAllFilesDelete = allowed)
            }
        )
        hasAccess("/", WRITE).then(
            (allowed) => {
                this.allowMyFilesEdit = allowed
                hasAccess("/", DELETE).then((allowed) => this.allowMyFilesDelete = allowed)
            }
        )
    },
    computed: {
        ...mapGetters('files', ['files', 'fileLoaded', 'total', 'isImage', 'isText', 'isZip', 'isPdf', 'isWord', 'isXls']),
        ...mapGetters('filter', ['filter', 'getPage']),
        ...mapGetters('brands', ['brands',]),
        ...mapGetters('users', ['users', 'usersLoaded']),
        ...mapGetters('translations', ['t',]),
        ...mapGetters('user', ['getLocaleDateTimeFormat', 'getCurrentUserId']),
    },
    methods:  {
        ...mapActions('files', ['dropFile', 'dropFiles', 'load']),
        ...mapActions('users', ['loadUsers']),
        ...mapActions('filter', [
            'setSearch', 'setBrand', 'setUser', 'setMode', 'setLimit', 'setOffset', 'setSort', 'setOrder'
        ]),
        ...mapMutations('files', ['SET_LOADED']),
        showPreview(file) {
            this.previewModalVisible = true;
            this.previewUrl = file['file_uri'];
        },
        onChangePage(page) {
            this.saveSelectedRows();
            this.setOffset(this.filter.limit * (page - 1))
            this.restoreSelectedRows();
        },
        saveSelectedRows() {
            this.selectedRowsMem[this.getPage] = this.selectedRows;
        },
        restoreSelectedRows() {
            if (this.selectedRowsMem[this.getPage] !== undefined) {
              this.selectedRows = this.selectedRowsMem[this.getPage];
            } else {
              this.selectedRows = [];
            }
        },
        onShowSizeChange(page, show) {
            this.setLimit(show)
            this.setOffset(show * (page - 1))
            this.selectedRowsMem = {};
        },
        debounceSearch() {
            clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
                this.setSearch(this.search)
                this.onSearch(true)
            }, 500)
        },
        sorter(a, b, sort) {
            this.setSort(sort.field)
            this.setOrder(sort.order)
            this.onSearch()
        },
        onChangeBrand(value) {
            this.setBrand(value)
            this.onSearch()
        },
        onChangeUser(value) {
            this.setUser(value)
            this.onSearch(true)
        },
        onChangeMode(e) {
            this.setMode(e.target.value)

            if (e.target.value === 'all' && this.users && !this.users.usersLoaded) {
                this.loadUsers()
            }
            this.onSearch(true)
        },
        onSearch(reset) {
            if (reset) {
                this.setOffset(0)
            }
            this.load(this.filter)
        },
        formatLabelInfo(str) {
            let label = str.charAt(0).toUpperCase() + str.slice(1)

            return this.t(label)
        },
        isShowFileInfo(file) {
            return this.showFileInfo[file.id]
        },
        toggleShowFileInfo(file) {
            this.showFileInfo[file.id] = (this.showFileInfo[file.id]) === undefined ? true : !this.showFileInfo[file.id];
        },
        formatDateTime(value) {
            return moment(value).format(this.getLocaleDateTimeFormat)
        },
        copyUrl(url) {
            navigator.clipboard.writeText(url);
            this.urlCopied = url;

        },
        deleteFile(id) {
            this.dropFile(id).finally(() => {
                this.onSearch()
            })
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows;
        },
        deleteSelectedFiles() {
            let ids = []
            this.selectedRows.forEach(file => {
                ids.push(file.id)
            })
            this.dropFiles(ids).finally(() => {
                this.deselectRows()
                this.onSearch()
            })
        },
        downloadSelectedFiles() {
            this.saveSelectedRows();
            for (let page in this.selectedRowsMem) {
              this.selectedRowsMem[page].forEach(file => {
                const a = document.createElement('a')
                a.href = file["file_uri"].replace(/https?:/, '')
                a.download = file["file_uri"].split('/').pop()
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
              })
            }

            this.deselectRows()
        },
        deselectRows() {
            this.selectedRowKeys = [];
            this.selectedRows = []
            this.selectedRowsMem = {};
        },
        getReplaceUrl(fileId) {
            return `/admin/replace/${fileId}`
        },
        onChangeUpload({file}) {
            if (file.xhr && (file.xhr.status >= 200 || file.xhr.status <300 )) {
                this.onSearch(true)
            }
        },
        beforeUpload(file) {
            if (file.size > FILE_LIMIT) {
                notification.open({
                    message: 'Error',
                    description: this.t('The new file is over size 100 MB'),
                    icon: <FrownOutlined />,
                });

                return false;
            }
        },
        allowFilesEdit: function (userId) {
            if (userId === this.getCurrentUserId) {
                return this.allowMyFilesEdit
            }

            return this.allowAllFilesEdit
        },
        allowFilesDelete: function (userId) {
            if (userId === this.getCurrentUserId) {
                return this.allowMyFilesDelete
            }

            return this.allowAllFilesDelete
        },
    }

}


</script>


<style lang="less">
.ellipsize-left {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 210px;
    max-width: 295px;
    color: #1890ff;
    direction: rtl;
    text-align: left;
    display: inline-block;
    position: relative;
    top: 3px;
    left: -8px;
}

.ellipsis-long-text {
    max-width: 230px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.copy-block {
    position: relative;
    display: inline-block;
    top: -4px;
    right: 7px
}

.nowrap-text {
    white-space: nowrap;
}

.ant-select-selection-item span {
    color: rgb(199 199 199);
}

.ant-modal {
    text-align: center;
}

.ant-modal-content {
    display: inline-block;
}

.ant-table-thead {
    th:not(:last-of-type):not(:first-of-type) {

        .ant-table-column-title {
            padding-left: 14px !important;
        }
    }

    th:last-of-type {
        div {
            text-align: center;
        }

    }
}

.ant-table-tbody {
    td:not(:last-of-type):not(:first-of-type) {
        padding-left: 29px !important;
    }

    td:last-of-type {
        text-align: center;
    }
}

.ant-table {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 16px 0;
    }
    .anticon {
        color: #1890FF;
        font-size: 16px;
        margin: 0 .5rem;
    }
}

.filter-files-owner {
    width: 102px;
    padding: 0 10px !important;
    text-align: center;
}

.ant-modal {
    text-align: center;
}

.ant-modal-content {
    display: inline-block;
}

.ant-upload.ant-upload-select {
    display: inline !important;
}

.actions-buttons {
    .ant-btn {
        span.anticon {
            margin: 0;
            color: #262626 !important;
            font-size: 14px;
            vertical-align: baseline;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

    }

    .ant-btn:hover {

        border-color: rgba(0, 0, 0, 0.16);

        span.anticon {
            color: #40a9ff !important;
        }

    }

    .ant-btn:focus {
        border: 1px solid #096DD9;

        span.anticon {
            color: #40a9ff !important;
        }
    }
}

.confirm-text {
    font-weight: 500;
    margin-left: .5rem;
}

.filter-col {
    margin-right: 30px;
}

.filter-label {
    margin-bottom: .5rem;
    font-size: 14px;
}

.ant-select-arrow .anticon {
    margin: 0;
}

.ant-radio-button-wrapper:not:first-child {
    border-left: 0 !important;
}

a.copy {
    span {
        vertical-align: baseline;
    }
}

.ant-input-group-wrapper.search {

    width: 210px;

    .ant-input-group-addon {
        padding: 0 4px;

        span {
            font-size: 13px;
            vertical-align: baseline;
            color: rgba(0, 0, 0, 0.25) !important;
        }

    }
}


.ant-select-arrow {

    span {
        color: #000 !important;
        font-size: 11px !important;
    }

}

.ant-table-column-sorter-up, .ant-table-column-sorter-down {
    color: #BFBFBF !important;
}

th .ant-table-header-column {
    width: 100%;
    border-left: 1px solid #EBEBEB;
    padding: 0 1rem;
}

th:first-child .ant-table-header-column {
    border: none;
    padding: 0 1rem;
}


.ant-pagination-item-active {
    border-color: #3A464E;
}

.ant-pagination-item-link span {
    font-size: 12px;
    vertical-align: baseline;
    color: #D9D9D9 !important;
}

.ant-pagination-item a {
    color: #3A464E !important;
}

.ant-pagination-next, .ant-pagination-prev {
    a span {
        color: rgba(0, 0, 0, 0.65) !important;
    }
}

.ant-pagination-next:hover, .ant-pagination-prev:hover {
    a {
        border: 1px solid #3A464E !important;
    }

    &
    .ant-pagination-disabled {
        a {
            border-color: #d9d9d9 !important;
        }
    }
}


.ant-pagination-item-active, .ant-pagination-item:hover {
    border-color: #3A464E !important;
}

.replace-file-input {
    display: none;
}

.replace-file-label {
    cursor: pointer;
}

.tooltip-text {
    margin-bottom: 0;
}


.select-brand, .select-user {
    width: 210px;
}


#info > li {
    list-style-type: none;
}

#info {
    margin-left: 0;
    padding-left: 0;
}

.ant-table-column-sorter .on {
    color: #848484 !important;
}

.modal-img-preview {
    .ant-modal {
        width: auto !important;
    }
    .ant-modal-body img {
        max-width: 50vw;
        max-height: 50vw;
    }
}

</style>