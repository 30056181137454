<template>
    <li class="dropdown-item dropdown-submenu">
        <a :href="href">
            <slot></slot>
        </a>
        <ul v-if="subMenuItems.length" class="dropdown-menu dropdown-submenu">
            <template v-for="item in subMenuItems">
                <li class="dropdown-item" :key="item.id" v-if="!item.subMenuItems.length">
                    <a :href="href">{{ item.title }}</a>
                </li>
                <drop-down-sub-menu v-else
                                    :key="item.id"
                                    :subMenuItems="item.subMenuItems"
                                    :href="item.url">
                    {{ item.title }}
                </drop-down-sub-menu>
            </template>
        </ul>
    </li>
</template>


<script>
export default {
    name:  'DropDownSubMenu',
    props: {
        href:         {
            type:    String,
            default: '#'
        },
        subMenuItems: {
            type:    Array,
            default: function () {
                return []
            }
        }
    }
}
</script>

<style>
li.dropdown-submenu {
    position: relative;
}

li.dropdown-submenu > a:after {
    content: '\e9c7';
    font-family: 'icomoon';
    position: absolute;
    top: 0.5rem;
    right: 15px;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 0.8;
    vertical-align: middle;
    filter: alpha(opacity=80);
}

li.dropdown-submenu:hover > ul.dropdown-submenu {
    display: block;
}

li.dropdown-submenu {
    position: relative;
}

li.dropdown-submenu > ul.dropdown-submenu {
    top: -3px;
    left: 99.5%;
    display: none;
}
</style>