import store from './store';

export const humanReadableSize = (bytes) => {
        const dp = 1
        const thresh = 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = ['kB', 'MB', 'GB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        let sz = store.getters['translations/t'](units[u])

        return bytes.toFixed(dp) + ' ' + sz;
}