const state = {
        brand_id: null,
        mode: "my",
        search: null,
        user_id: null,
        limit: 10,
        offset: 0,
        sort: "created",
        order: "DESC"
};

const getters = {
    getPage: state => 1 + state.offset/state.limit,
    filter(state) {
        return state
    },
};

const actions = {
    setBrand({commit}, value) {
        commit('SET_FILTER_VALUE', {field: 'brand_id', value: value});
    },
    setUser({commit}, value) {
        commit('SET_FILTER_VALUE', {field: 'user_id', value: value});
    },
    setMode({commit}, value) {
        commit('SET_FILTER_VALUE', {field: 'mode', value: value});
    },
    setSearch({commit}, value) {
        commit('SET_FILTER_VALUE', {field: 'search', value: value});
    },
    setLimit({commit}, value) {
        commit('SET_FILTER_VALUE', {field: 'limit', value: value});
    },
    setOffset({commit}, value) {
        commit('SET_FILTER_VALUE', {field: 'offset', value: value});
    },
    setSort({commit}, value) {
        commit('SET_FILTER_VALUE', {field: 'sort', value: value});
    },
    setOrder({commit}, value) {
        switch (value) {
            case 'ascend':
                value = 'ASC'
                break;
            case 'descend':
            default:
                value = 'DESC'
                break;
        }


        commit('SET_FILTER_VALUE', {field: 'order', value: value});
    },
};

const mutations = {
    SET_FILTER_VALUE(state, {field, value}) {
        Object.assign(state, {
            [field]: value
        });
    }
};

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations,
}

