import api from './store/api.js'
import { get } from 'lodash';

const accesses = new Map();

export const CREATE = 1;
export const READ = 2;
export const WRITE = 4;
export const DELETE = 8;

export const hasAccess = (resource, mode = READ) => {
    if (accesses.has(resource)) {
        return new Promise((resolve) => {
            resolve((accesses.get(resource) & mode) > 0);
        });
    }

    return api.getAccess(resource).then((response) => {
        let accessMode = get(response, 'data.data.accessMode', 0);
        accesses.set(resource, accessMode);

        return (accessMode & mode) > 0;
    });
}
