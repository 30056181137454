import api from '../api'
import {ALL} from '../../const'
import {find, sortBy} from 'lodash'

const state = {
    loading: false,
    loaded:  false,
    locales: []
};

const getters = {
    getTranslatable:  state => {
        return state.locales.filter(l => {
            return l.translation_required
        })
    },
    getOptions:       state => {
        let options = state.locales.map(locale => ({name: locale.name, id: locale.id}));
        options.unshift({name: ALL, id: null});

        return options;
    },
    getOptionsStrict: state => state.locales.map(locale => ({name: locale.name, id: locale.id})),
    getTitleOptions:  state => {
        let options = state.locales.map(locale => ({
                name: locale.description.charAt(0).toUpperCase() + locale.description.slice(1) + ' (' + locale.title + ')',
                id:   locale.id
            })
        );
        options = sortBy(options, ['name']);
        options.unshift({name: ALL, id: null});

        return options;
    },
    getTranslatableTitleOptions:  (state, getters) => {
        let options = getters.getTranslatable.map(locale => ({
                name: locale.description.charAt(0).toUpperCase() + locale.description.slice(1) + ' (' + locale.title + ')',
                id: locale.id
            })
        );
        options = sortBy(options, ['name']);
        options.unshift({name: ALL, id: null});
        return options;
    },
    getNames:         state => state.locales.map(locale => (locale.name)),
    findByName:       state => name => find(state.locales, locale => locale.name == name),
    findById:         state => id => find(state.locales, locale => locale.id == id)
};

const actions = {
    async loadOnce({commit, state}) {
        if (!state.loaded) {
            commit('LOADING', true);
            let locales = await api.getLocales().finally(() => {
                commit('LOADING', false)
            });
            commit('LOCALES', locales.data.data);
            commit('LOADED', true);
        }
    }
};

const mutations = {
    LOCALES(state, locales) {
        state.locales = locales
    },
    LOADING(state, loading) {
        state.loading = loading
    },
    LOADED(state, loaded) {
        state.loaded = loaded
    }
};

export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}
