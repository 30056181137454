<template>
    <nav class="navbar navbar-expand-md navbar-dark">
        <div class="container-fluid">
            <a :href="getHomeUrl" class="navbar-brand" >
                <img src="/img/logo.svg">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <a data-toggle="collapse"><i class="icon-tree5"></i></a>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <template v-for="item in user.menu" :key="item.id">
                        <li class="nav-item" v-if="!item.subMenuItems.length">
                            <a class="nav-link active" aria-current="page" href="#">{{item.title}}</a>
                        </li>
                        <li class="nav-item dropdown" v-else>
                            <a class="nav-link dropdown-toggle" href="#" :id="'dropdownMenu'+item.id"
                               role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {{item.title}}
                            </a>
                            <ul class="dropdown-menu " aria-labelledby="navbarDropdown">
                                <template v-for="sub in item.subMenuItems" :key="sub.id">
                                    <li  v-if="!sub.subMenuItems.length"><a class="dropdown-item" :href="sub.url">{{sub.title}}</a></li>
                                        <drop-down-sub-menu v-else
                                                            :key="sub.id"
                                                            :subMenuItems="sub.subMenuItems"
                                                            :href="sub.url">
                                            {{sub.title}}
                                        </drop-down-sub-menu>
                                </template>
                            </ul>
                        </li>
                    </template>
                </ul>

                <ul class="navbar-nav ml-auto">
                    <li class="nav-item dropdown ">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarFlagDropdown"
                           role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img :src="getCurrentFlagUrl" width="20">
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right">
                            <li>
                                <a class="dropdown-item" v-for="locale in layout.locales"
                                   @click="changeLocale(locale.name)"
                                   :key="locale.name" href="#">
                                    <img :src="getFlagUrl(locale.flag)" width="20" style="margin-right: .5rem;">
                                    {{locale.title}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarUserDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i :class="['icon-user', 'mr-2']"></i>
                            {{ user.name }}
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarUserDropdown">
                            <li  v-for="item in user.userMenu" :key="item.url">
                                <a class="dropdown-item" :href="item.url">
                                    <i :class="'icon-' + item.icon" class="mr-2"></i>{{item.title}}
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>

        </div>
    </nav>


</template>


<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import dropDownSubMenu from "@/components/custom/DropDownSubMenu";

export default {
    components: {
        dropDownSubMenu
    },
    computed:   {
        ...mapState('user', {
            user:         state => state.current,
            layout:       state => state.layout,
        }),
        ...mapGetters('user', ['getFlagUrl', 'getCurrentFlagUrl', 'getHomeUrl', 'getCurrentMenu',]),

    },
    methods:    {
        ...mapActions('user', [
            'changeLocale'
        ])
    }
}
</script>


<style lang="less">
#app {

    .navbar-brand img {
        height: 27px;
    }

    .navbar {
        background-color: #37474F;
        padding: 0 1.5rem;
        margin: 0;
        min-height: 3.5rem;

        .navbar-toggler {
            color: rgba(255, 255, 255, 1);
            border: 0;
            outline: 0
        }

        .nav-item {
            padding: 0.6rem 0.25rem;

            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        .nav-item.dropdown.show {
            background-color: rgba(0, 0, 0, 0.1);
        }

        .nav-link {
            color: rgba(255, 255, 255, 1);
        }
    }

    .navbar-nav {
        margin-left: 2rem
    }

    .dropdown-menu {
        border-radius: 0;
        padding: 0.2rem 0;
        min-width: 180px;
        margin-top: 0;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.075);
    }
    .dropdown-menu-right {
        right: 0;
        left: auto;
    }

    .dropdown-item {
        padding: 0.35rem 1.25rem;
        color: #212529;
        text-decoration: none;
    }

    .dropdown-item a {
        color: #212529;
        text-decoration: none;

        &:hover {
            color: #212529;
        }
    }

    .dropdown-toggle::after {
        display: inline-block;
        font-size: 1.25rem;
        width: 1rem;
        line-height: 1;
        content: '\e9c5';
        font-family: 'icomoon';
        vertical-align: middle;
        border: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    li .dropdown-item:active {
        background-color: #f5f5f5;
    }

    a.dropdown-item {
        &:hover {
            color: #212529;
        }

    }

}
</style>

