<template>
  <div>
    <admin-menu></admin-menu>
      <div class="container-fluid">
          <router-view class="router-view"></router-view>
          <uploading></uploading>
      </div>

  </div>
</template>

<script>
import AdminMenu from './Menu';
import Uploading from './files/Uploading';


export default {
    components: {
        AdminMenu, Uploading
    },
}

</script>
