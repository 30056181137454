import _ from "lodash"

const state = {
    files: [],
};

const getters = {
    files(state) { return _.cloneDeep(state.files) },
};

const actions = {
    setFiles({commit}, files) {
        commit('UPLOADING_FILES', files);
    }
};

const mutations = {
    UPLOADING_FILES(state, files) {
        state.files = _.cloneDeep(files)
    },
    RESET_FILES(state) {
        state.files = []
    }
};


export default {
    namespaced: true,
    getters,
    state,
    actions,
    mutations
}

