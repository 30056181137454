import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Storage from 'vue-web-storage'
import store from "@/store";
import { humanReadableSize } from './filters'

import 'bootstrap'
import "ant-design-vue/dist/antd.css";


const app = createApp(App)

app.use(store)
app.use(Storage, {
    drivers: ['session', 'local']
});
app.use(router)

app.config.globalProperties.$filters = {
    humanReadableSize
}

app.mount('#app')

export default app;
